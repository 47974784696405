import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    // backend: {
    //     loadPath: "http://localhost:5173/i18n/{{lng}}.json",
    //   },
    resources: {
        en: {
            translation: {
                home: 'Home',
                admin: 'Admin',
                choose: 'Choose',
                input: 'Input',
                help: 'Help',
                filter: 'Filter',
                search: 'Search',
                share: 'Share',
                customize: 'Customize',
                setting: 'Setting',
                export: 'Export',
                add: 'Add',
                edit: 'Edit',
                delete: 'Delete',
                rename: 'Rename',
                workflow: 'Workflow',
                DateCreated: 'Created date',
                cancel: 'Cancel',
                create: 'Create',
                save: 'Save',
                value: 'Value',
                "data table": "Data table",
                sort: "Sort",
                name: "Name",
                description: "Description",
                action: "Action",
                domain: "Domain",
                project: "Project",
            }
        },
        vi: {
            translation: {
                home: 'Trang chủ',
                admin: 'Quản trị',
                choose: 'Chọn',
                input: 'Nhập',
                help: 'Hỗ trợ',
                filter: 'Bộ lọc',
                search: 'Tìm kiếm',
                share: 'Chia sẻ',
                customize: 'Tùy chỉnh',
                setting: 'Cài đặt',
                export: 'Xuất tệp',
                add: 'Tạo',
                edit: 'Chỉnh sửa',
                delete: 'Xóa',
                rename: 'Đổi tên',
                workflow: 'Quy trình',
                DateCreated: 'Ngày tạo',
                cancel: 'Hủy',
                create: 'Tạo',
                save: 'Lưu',
                value: 'Giá trị',
                "data table": "Bảng dữ liệu",
                sort: "Sắp xếp",
                name: "Tên",
                description: "Mô tả",
                action: "Hành động",
                domain: "Tên miền",
                project: "Dự án",
            }
        },
    },
});

export default i18n;