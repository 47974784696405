import { useRef, useState } from 'react';
import styles from './index.module.css'
import { Button, Text, TextArea, Winicon } from "wini-web-components";
import { useNavigate } from 'react-router-dom';

export default function CreateToilet() {
    const textAreaRef = useRef()
    const navigate = useNavigate()
    const [helperText, setHelperText] = useState()

    return <div className="row main-layout">
        <div className={`row ${styles["header"]}`}>
            <Winicon src="outline/user interface/home" size={"1.6rem"} style={{ padding: "0.2rem" }} />
            <div className="row" style={{ flex: 1, gap: "0.4rem" }}>
                <Text className="button-text-6" style={{ color: "var(--neutral-text-subtitle-color)" }}>Trang chủ /</Text>
                <Text className="button-text-6" style={{ color: "var(--neutral-text-title-color)" }}>Đăng ký xây mới nhà vệ sinh</Text>
            </div>
            <Button label="Help center" />
            <Button label="Save & Close" />
        </div>
        <div className='row' style={{ justifyContent: "center", padding: "8dvh 3.2rem 2.4rem", width: "100%", alignContent: "start" }}>
            <div className='row col24-min col24-sm col24-md col24-lg' style={{ width: '64.6vw', gap: '6.4rem', '--gutter': '0px', alignItems: "stretch", flexWrap: "wrap", alignContent: "start" }}>
                <div className='col col24-min col24-sm' style={{ '--gutter': "6.4rem", padding: "1.6rem 0", gap: "2.4rem", flex: 1 }}>
                    <div className='col' style={{ gap: 4 }}>
                        <Text className='subtitle-4'>Bước 1</Text>
                        <Text className='heading-4'>Chia sẻ thông tin về nhà vệ sinh bạn mong muốn xây dựng</Text>
                        <Text className='body-2' style={{ marginTop: 4 }}>
                            Trong bước này, chúng tôi sẽ hỏi xem bạn mong muốn xây nhà vệ sinh như nào. Từ đó chúng tôi có thể từ mong muốn của bạn để tư vấn thiết kế xây dựng phù hợp với mong muốn, chi phí của bạn
                        </Text>
                    </div>
                    <TextArea
                        ref={textAreaRef}
                        className='placeholder-2'
                        placeholder='Mô tả thông tin của bạn'
                        style={{ height: "20.4rem", padding: "1.6rem" }}
                        helperText={helperText}
                        onFocus={() => { setHelperText() }}
                    />
                    <div className='row' style={{ gap: "1.2rem" }}>
                        <Button
                            label="Gửi thông tin"
                            style={{ padding: "1rem 1.6rem", height: "4rem" }}
                            onClick={() => {
                                if (!textAreaRef.current.getTextarea().value) setHelperText("Vui lòng nhập thông tin mô tả")
                                else navigate(window.location.pathname.replace("/create", "/create-flow") + window.location.search, { state: { step: 2, description: textAreaRef.current.getTextarea().value } })
                            }}
                        />
                        <Button
                            className='button-primary'
                            label="Tự xây nhà vệ sinh của bạn"
                            style={{ padding: "1rem 1.6rem", height: "4rem" }}
                            onClick={() => { navigate(window.location.pathname.replace("/create", "/create-flow") + window.location.search, { state: { step: 0, description: textAreaRef.current.getTextarea().value } }) }}
                        />
                    </div>
                </div>
                <div className='col24-min col24-sm' style={{ '--gutter': "6.4rem", width: "64rem" }}>
                    <img src='https://file-mamager.wini.vn/Upload/2024/12/media1_7d48.png' alt='' style={{ width: "100%", objectFit: "cover", aspectRatio: "1 / 1" }} />
                </div>
            </div>
        </div>
    </div>
}