export enum ToiletType {
    forWorker = 1,
    forOffice = 2,
    forBuilding = 3,
    forFamily = 4
} 

export enum ToiletPlace {
    outDoor = 1,
    inDoor = 2,
} 

export enum ToiletStatus {
    register = 1,
    advice = 2,
    contract = 3,
    design = 4
}