import styles from './view.module.css'
import { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage, Winicon } from "wini-web-components";
import { hashPassword, regexGetVariableByThis, RenderComponentByType, validateForm } from "../config";
import { ComponentType, FEDataType } from "../../wini/table/da";
import { randomGID, Ultis } from "../../../Utils";
import { DataController } from "../controller";
import { TableController } from "../../wini/table/controller";
import { Select1Form, SelectMultipleForm } from "../../../project-component/component-form";
import { BaseDA, imgFileTypes } from "../../../da/baseDA";
import ConfigApi from "../../../da/configApi";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const PopupAddEditData = forwardRef(function PopupAddEditData(data, ref) {
    const selectedM = useSelector((store) => store.module.data)
    const _dataController = new DataController(data.module)
    const [item, setItem] = useState()
    const [column, setColumn] = useState([])
    const [relative, setRelative] = useState([])
    const dialogRef = useRef()
    const { t } = useTranslation()

    //#region getSetting
    const getSetting = async () => {
        const _colController = new TableController("column")
        const _relController = new TableController("rel")
        _colController.getListSimple({
            page: 1,
            size: 100,
            query: `@TableName:{${data.module}} -@Name:{Id | DateCreated}`,
            returns: ["Id", "Name", "DataType", "Query", "Form"]
        }).then(res => {
            if (res.totalCount) setColumn(res.data.filter(e => selectedM.Setting?.column?.[e.Name] !== -1).map((e, i) => {
                e.Form = e.Form ? JSON.parse(e.Form) : {}
                e.Form.Sort ??= i
                if (!e.Form.Placeholder?.length) e.Form.Label ??= e.Name
                switch (e.DataType) {
                    case FEDataType.GID:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.STRING:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.BOOLEAN:
                        e.Form.ComponentType ??= ComponentType.checkbox
                        break;
                    case FEDataType.NUMBER:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.DATE:
                        e.Form.ComponentType = ComponentType.datePicker
                        break;
                    case FEDataType.DATETIME:
                        e.Form.ComponentType = ComponentType.dateTimePicker
                        break;
                    case FEDataType.MONEY:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.PASSWORD:
                        e.Form.ComponentType = ComponentType.textField
                        break;
                    case FEDataType.FILE:
                        e.Form.ComponentType = ComponentType.upload
                        break;
                    default:
                        break;
                }
                return e
            }))
        })
        _relController.getListSimple({
            page: 1,
            size: 100,
            query: `@TableFK:{${data.module}}${data.action ? ` -@Column:{${selectedM.TableId === data.action.TbName ? "ParentId" : `${selectedM.TableId}Id`}}` : ""}`,
            returns: ["Id", "Column", "Form", "TablePK", "Query"]
        }).then(res => {
            if (res.totalCount) setRelative(res.data.filter(e => selectedM.Setting?.column?.[e.Column] !== -1).map((e, i) => {
                e.Form = e.Form ? JSON.parse(e.Form) : { Label: e.Column, ComponentType: ComponentType.select1, Required: true }
                e.Form.Sort ??= i
                return e
            }))
        })
    }

    //#region get item data
    const getData = async () => {
        if (data.id) {
            _dataController.getById(data.id).then(res => {
                if (res.code === 200) setItem(res.data)
                else ToastMessage.errors(res.message)
            })
        } else if (data.action) {
            const defaultItem = {}
            if (data.action.TbName === selectedM.TableId) defaultItem["ParentId"] = data.action.value
            else defaultItem[`${selectedM.TableId}Id`] = data.action.value
            setItem(defaultItem)
        }
    }

    useEffect(() => {
        getData()
        getSetting()
    }, [])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <Dialog ref={dialogRef} />
        <div className='popup-header row' style={{ gap: '0.8rem', padding: '0.8rem', paddingLeft: '2.4rem' }}>
            <Text className="heading-8" style={{ flex: 1 }}>{data.id ? `${t("edit")} ${selectedM.Name}` : `${t("add")} ${selectedM.Name}`}</Text>
            <button type="button" className="row icon-button32" onClick={() => { closePopup(ref) }}><Winicon src={"fill/user interface/e-remove"} size="2.4rem" /></button>
        </div>
        {column.length && (!data.id || item) ? <FormView
            cols={column.filter(e => !e.Query?.length)}
            rels={relative}
            item={item}
            module={data.module}
            onCancel={() => {
                showDialog({
                    ref: dialogRef,
                    alignment: DialogAlignment.center,
                    status: ComponentStatus.WARNING,
                    submitTitle: "Submit",
                    title: 'Confirm cancel ' + (data.item ? 'update' : 'add'),
                    onSubmit: () => { closePopup(ref) }
                })
            }}
            onSuccess={() => {
                closePopup(ref)
                ToastMessage.success(`${data.item ? 'Update' : 'Add'} ${data.module} successfully!`)
                data.onSuccess()
            }}
        /> : null}
    </div>
})

export default PopupAddEditData

//#region form
const FormView = ({ cols = [], rels = [], item, module, onCancel, onSuccess }) => {
    const _dataController = new DataController(module)
    const methods = useForm({ shouldFocusError: false, defaultValues: { Id: randomGID() } })
    const [watchRel, setWatchRel] = useState([])
    const [staticRel, setStaticRel] = useState([])
    const methodOptions = useForm({ shouldFocusError: false })

    const onSubmit = async (ev) => {
        let dataItem = { ...ev }
        dataItem.DateCreated ??= Date.now()
        let validateDataForm = {}
        Object.keys(dataItem).forEach((key) => {
            if (typeof dataItem[key] === "string") validateDataForm[key] = dataItem[key].trim()
        })
        const _val = await validateForm({
            list: cols.filter(e => e.Form.Validate?.length).map(e => {
                return {
                    Name: e.Name,
                    Validate: e.Form.Validate
                }
            }),
            formdata: validateDataForm
        })
        // Cập nhật lỗi vào React Hook Form
        if (_val && Object.keys(_val).length > 0) {
            Object.keys(_val).forEach((field) => {
                methods.setError(field, { message: _val[field].join(', ') });
            });
            return;
        }
        // Nếu có lỗi, dừng lại không thực hiện submit
        for (let _col of cols) {
            if (_col.Name === "DateCreated") {
                dataItem[_col.Name] ??= Date.now()
            } else if (dataItem[_col.Name] != undefined) {
                if (!_col.Query) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            break;
                        case FEDataType.STRING:
                            if (Array.isArray(dataItem[_col.Name])) {
                                dataItem[_col.Name] = dataItem[_col.Name].join(",")
                            } else if (typeof dataItem[_col.Name] !== 'string') {
                                dataItem[_col.Name] = `${dataItem[_col.Name]}`
                            }
                            break;
                        case FEDataType.BOOLEAN:
                            dataItem[_col.Name] = [true, 1, "true"].includes(dataItem[_col.Name]) ? true : false
                            break;
                        case FEDataType.NUMBER:
                            dataItem[_col.Name] = typeof dataItem[_col.Name] === 'string' ? parseFloat(dataItem[_col.Name]) : dataItem[_col.Name]
                            break;
                        case FEDataType.DATE:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name]).getTime()
                            break;
                        case FEDataType.DATETIME:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name], 'dd/mm/yyyy hh:mm:ss').getTime()
                            break;
                        case FEDataType.MONEY:
                            dataItem[_col.Name] = parseInt(dataItem[_col.Name].replaceAll(',', ''))
                            break;
                        case FEDataType.PASSWORD:
                            dataItem[_col.Name] = await hashPassword(dataItem[_col.Name])
                            break;
                        case FEDataType.FILE:
                            if (ev[_col.Name]) {
                                if (ev[_col.Name] instanceof File) {
                                    const res = await BaseDA.uploadFiles([ev[_col.Name]])
                                    if (res[0]) dataItem[_col.Name] = res[0].id
                                } else {
                                    dataItem[_col.Name] = ev[_col.Name].id
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        }
        for (let _rel of rels) {
            if (dataItem[_rel.Column] && Array.isArray(dataItem[_rel.Column]))
                dataItem[_rel.Column] = dataItem[_rel.Column].join(",")
        }

        const res = await _dataController.add([dataItem])
        if (res.code !== 200) return ToastMessage.errors(res.message)
        onSuccess()
    }

    const onError = (ev) => { }

    useEffect(() => {
        if (cols.length) {
            if (item) {
                const _fileIds = []
                Object.keys(item).forEach(prop => {
                    const _col = cols.find(e => e.Name === prop)
                    const _rel = rels.find(e => e.Column === prop)
                    if (_col) {
                        switch (_col.DataType) {
                            case FEDataType.GID:
                                methods.setValue(prop, item[prop])
                                break;
                            case FEDataType.STRING:
                                if (_col.Form.Options?.length) {
                                    methods.setValue(prop, (item[prop] ?? "").split(","))
                                } else {
                                    methods.setValue(prop, item[prop])
                                }
                                break;
                            case FEDataType.HTML:
                                methods.setValue(prop, item[prop])
                                break;
                            case FEDataType.BOOLEAN:
                                methods.setValue(prop, item[prop])
                                if (_col.Form.ComponentType === ComponentType.radio) methods.setValue(prop, `${item[prop]}`)
                                break;
                            case FEDataType.NUMBER:
                                methods.setValue(prop, typeof item[prop] === 'string' ? parseFloat(item[prop]) : item[prop])
                                break;
                            case FEDataType.DATE:
                                methods.setValue(prop, Ultis.datetoString(new Date(typeof item[prop] === 'string' ? parseInt(item[prop]) : item[prop])))
                                break;
                            case FEDataType.DATETIME:
                                methods.setValue(prop, Ultis.datetoString(new Date(typeof item[prop] === 'string' ? parseInt(item[prop]) : item[prop]), 'dd/mm/yyyy hh:mm:ss'))
                                break;
                            case FEDataType.MONEY:
                                methods.setValue(prop, Ultis.money(item[prop]))
                                break;
                            case FEDataType.PASSWORD:
                                methods.setValue(prop, item[prop])
                                break;
                            case FEDataType.FILE:
                                if (item[prop]) _fileIds.push({ id: item[prop], name: prop })
                                break;
                            default:
                                break;
                        }
                    } else if (_rel) {
                        const _tmpParse = item[prop]?.length ? item[prop].split(",") : []
                        methods.setValue(prop, _rel.Form.ComponentType === ComponentType.selectMultiple ? _tmpParse : _tmpParse[0])
                    } else {
                        methods.setValue(prop, item[prop])
                    }
                })
                if (_fileIds.length) {
                    BaseDA.getFilesInfor(_fileIds.map(e => e.id)).then(res => {
                        if (res) _fileIds.forEach(e => {
                            const _file = res.find(_file => _file.id === e.id)
                            if (_file) methods.setValue(e.name, { ..._file, type: _file.type ? _file.type : imgFileTypes.some(t => _file.name.toLowerCase().endsWith(t)) ? "image" : "file", url: ConfigApi.imgUrlId + _file.id })
                        })
                    })
                }
            } else {
                cols.filter((e) => e.Form?.DefaultValue != undefined && e.Form?.DefaultValue !== "").forEach((_col) => {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            methods.setValue(_col.Name, _col.Form.DefaultValue)
                            break;
                        case FEDataType.STRING:
                        case FEDataType.STRING:
                            if (_col.Form.Options?.length) {
                                methods.setValue(_col.Name, _col.Form.DefaultValue.split(","))
                            } else {
                                methods.setValue(_col.Name, _col.Form.DefaultValue)
                            }
                            break;
                        case FEDataType.HTML:
                            methods.setValue(_col.Name, _col.Form.DefaultValue)
                            break;
                        case FEDataType.BOOLEAN:
                            methods.setValue(_col.Name, _col.Form.DefaultValue)
                            break;
                        case FEDataType.NUMBER:
                            methods.setValue(_col.Name, typeof _col.Form.DefaultValue === 'string' ? parseFloat(_col.Form.DefaultValue) : _col.Form.DefaultValue)
                            break;
                        case FEDataType.DATE:
                            methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.Form.DefaultValue === 'string' ? parseInt(_col.Form.DefaultValue) : _col.Form.DefaultValue)))
                            break;
                        case FEDataType.DATETIME:
                            methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.Form.DefaultValue === 'string' ? parseInt(_col.Form.DefaultValue) : _col.Form.DefaultValue), 'dd/mm/yyyy hh:mm:ss'))
                            break;
                        case FEDataType.MONEY:
                            methods.setValue(_col.Name, Ultis.money(_col.Form.DefaultValue))
                            break;
                        case FEDataType.PASSWORD:
                            methods.setValue(_col.Name, _col.Form.DefaultValue)
                            break;
                        default:
                            break;
                    }
                })
            }
        }
    }, [item, cols.length])

    useEffect(() => {
        if (rels.length) {
            setStaticRel(rels.filter(e => !e.Query || !e.Query.match(regexGetVariableByThis)))
            setWatchRel(rels.filter(e => e.Query && e.Query.match(regexGetVariableByThis)?.length))
        }
    }, [rels])

    useEffect(() => {
        if (watchRel.length) getOptions({
            relatives: watchRel.filter(e => e.Query.match(regexGetVariableByThis).some(m => methods.watch(regexGetVariableByThis.exec(m)[1]))),
            isWatch: true
        })
    }, [methods.watch()])
  
    useEffect(() => {
        if (staticRel.length) getOptions({ relatives: staticRel })
    }, [staticRel])

    const getOptions = ({ relatives = [], isWatch = false }) => {
        relatives.forEach((_rel) => {
            const _dataPKController = new DataController(_rel.TablePK)
            if (_rel.TablePK === module) {
                _dataPKController.filterByEmptyKey({ page: 1, size: 500, searchRaw: _rel.Query?.length ? isWatch ? _rel.Query.replace(regexGetVariableByThis, (m) => methods.getValues(regexGetVariableByThis.exec(m)[1])) : _rel.Query : "*", key: `ParentId` }).then(async (res) => {
                    if (res.code === 200) methodOptions.setValue(`${_rel.Column}_Options`, res.data ?? [])
                })
            } else {
                _dataPKController.getListSimple({
                    page: 1, size: 1000,
                    query: isWatch ? _rel.Query.replace(regexGetVariableByThis, (m) => methods.getValues(regexGetVariableByThis.exec(m)[1])) : _rel.Query,
                    returns: ["Id", "Name", "ParentId"]
                }).then((res) => {
                    if (res.code === 200) methodOptions.setValue(`${_rel.Column}_Options`, res.data ?? [])
                })
            }
        })
    }

    return <form className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: '1.6rem 2.4rem', gap: '1.6rem', overflow: 'hidden auto' }}>
            {cols.map((e) => <RenderComponentByType key={e.Id} fieldItem={e} methods={methods} style={{ order: e.Form.Sort }} />)}
            {rels.map((_rel, _) => {
                const _options = methodOptions.watch(`${_rel.Column}_Options`) ?? []
                let _mapOptions = _options.map(e => { return { id: e.Id, name: e.Name, parentId: e.ParentId } })
                switch (_rel.Form.ComponentType) {
                    case ComponentType.selectMultiple:
                        return <SelectMultipleForm
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            placeholder={_rel.Form.Placeholder}
                            style={{ order: _rel.Form.Sort }}
                            options={_mapOptions}
                        />
                    default:
                        return <Select1Form
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            placeholder={_rel.Form.Placeholder}
                            style={{ order: _rel.Form.Sort }}
                            options={_mapOptions}
                        />
                }
            })}
        </div>
        <div className="row popup-footer">
            <Button
                label="Cancel"
                style={{ width: "7.2rem", borderRadius: '0.4rem', backgroundColor: "var(--neutral-main-background-color)", color: "var(--neutral-text-subtitle-color)" }}
                onClick={onCancel}
            />
            <Button
                label="Save"
                className="button-primary"
                style={{ width: "5.8rem", borderRadius: '0.4rem' }}
                onClick={methods.handleSubmit(onSubmit, onError)}
            />
        </div>
    </form>
}