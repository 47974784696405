import styles from './index.module.css'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Popup, Select1, showPopup, closePopup, Text, TextField, Winicon, NumberPicker, ToastMessage } from "wini-web-components";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RadioButtonForm, TextFieldForm } from '../../../project-component/component-form';
import { DataController } from '../../../screen/module/controller';
import ConfigApi from '../../../da/configApi';
import { randomGID, Ultis } from '../../../Utils';
import { ToiletPlace, ToiletStatus, ToiletType } from '../da';

export default function CreateFlow() {
    const methods = useForm({ shouldFocusError: false, defaultValues: { devices: [] } })
    const location = useLocation()
    const navigate = useNavigate()
    const [step, setStep] = useState(0)

    const returnStep = () => {
        switch (step) {
            case 0:
                return <SelectProductStep methods={methods} />
            case 1:
                return <SelectFeatureStep methods={methods} />
            case 2:
                return <InputContactStep methods={methods} />
            case 3:
                switch (parseInt(methods.getValues("Type"))) {
                    case ToiletType.forWorker:
                        var toiletName = "Toilet cho người lao động/sản xuất"
                        break;
                    case ToiletType.forOffice:
                        toiletName = "Toilet văn phòng"
                        break;
                    case ToiletType.forBuilding:
                        toiletName = "Toilet chung cư/toà nhà"
                        break;
                    case ToiletType.forFamily:
                        toiletName = "Toilet hộ gia đình"
                        break;
                    default:
                        break;
                }
                const newCustomer = {
                    Id: randomGID(),
                    Name: methods.watch("Name"),
                    DateCreated: Date.now(),
                    Mobile: methods.watch("Mobile"),
                    Address: methods.watch("Address"),
                }
                const newToilet = {
                    Id: randomGID(),
                    Name: toiletName ?? "Toilet tư vấn theo yêu cầu",
                    DateCreated: Date.now(),
                    CustomerId: newCustomer.Id,
                    Status: ToiletStatus.register,
                    Type: toiletName ? parseInt(methods.getValues("Type")) : null,
                    Place: toiletName ? parseInt(methods.getValues("Place")) : null,
                    Description: location.state.description
                }
                const registerDevices = methods.getValues("devices").map(e => {
                    return {
                        Id: randomGID(),
                        Name: e.Name,
                        DateCreated: Date.now(),
                        ToiletId: newToilet.Id,
                        ProductId: e.Id,
                        Quantity: e["_Quantity"]
                    }
                })
                return <LastStep
                    customer={newCustomer}
                    devices={registerDevices}
                    toilet={newToilet}
                />
            default:
                return <div />
        }
    }

    const checkValidNextStep = () => {
        switch (step) {
            case 0:
                return methods.watch("devices").length > 0
            case 1:
                return methods.watch("Type") && methods.watch("Place")
            case 2:
                return methods.watch("Name")?.length > 0 && methods.watch("Mobile")?.length > 0
            default:
                break;
        }
    }

    useEffect(() => {
        if (location.state.step) setStep(location.state.step)
    }, [location.state])

    return <div className="row main-layout">
        <div className={`row ${styles["header"]}`}>
            <Winicon src="outline/user interface/home" size={"1.6rem"} style={{ padding: "0.2rem" }} />
            <div className="row" style={{ flex: 1, gap: "0.4rem" }}>
                <Text className="button-text-6" style={{ color: "var(--neutral-text-subtitle-color)" }}>Home /</Text>
                <Text className="button-text-6" style={{ color: "var(--neutral-text-title-color)" }}>Process something</Text>
            </div>
            <Button label="Help center" />
            <Button label="Save & Close" />
        </div>
        <div className='col' style={{ height: "calc(100% - 7.6rem - 8.8rem - 8px)", width: "100vw" }}>
            {returnStep()}
        </div>
        {step < 3 ? <>
            <div className={`row ${styles["step-value"]}`}>{Array.from({ length: 4 }).map((_, i) => <div key={i} style={{ backgroundColor: i === step ? "var(--neutral-absolute-reverse-background-color)" : undefined }} />)}</div>
            <div className={`row ${styles["footer"]}`}>
                <Button
                    label="Back"
                    style={{ padding: "1rem 1.6rem", height: "4rem" }}
                    onClick={() => {
                        if (step) setStep(step - 1)
                        else navigate(-1)
                    }}
                />
                <div style={{ flex: 1 }} />
                <Button
                    className={checkValidNextStep() ? 'button-primary' : "button-disabled"}
                    label={step === 2 ? "Send information" : "Next"}
                    style={{ padding: "1rem 1.6rem", height: "4rem" }}
                    onClick={() => { setStep(step + 1) }}
                />
            </div>
        </> : null}
    </div>
}

//#region Select Product
const SelectProductStep = ({ methods }) => {
    const [selectedCate, setSelectedCate] = useState()
    const [cate, setCate] = useState([])
    const popupRef = useRef()

    const showPopupSelectProduct = (item) => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            content: <PopupSelectProduct
                ref={popupRef}
                item={item}
                selecteds={methods.getValues("devices").filter(e => e.CategoryId === item.Id)}
                onDone={(items) => {
                    methods.setValue("devices", [...methods.getValues("devices").filter(e => e.CategoryId !== item.Id), ...items.map(e => ({ ...e, "_Quantity": e["_Quantity"] ?? 1 }))])
                    closePopup(popupRef)
                }}
            />
        })
    }

    useEffect(() => {
        const cateController = new DataController("Category")
        cateController.filterByEmptyKey({ page: 1, size: 100, key: "ParentId", searchRaw: "*", sortby: [{ prop: "Sort", direction: "DESC" }] }).then(res => {
            if (res.code === 200) {
                setCate(res.data)
                setSelectedCate(res.data[0]?.Id)
            }
        })
    }, [])

    useEffect(() => {
        if (selectedCate && !methods.getValues(selectedCate)?.length) {
            const cateController = new DataController("Category")
            cateController.aggregateList({ page: 1, size: 20, searchRaw: `@ParentId:{${selectedCate}}` }).then(res => {
                if (res.code === 200) methods.setValue(selectedCate, { data: res.data, totalCount: res.totalCount })
            })
        }
    }, [selectedCate])

    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        <Popup ref={popupRef} />
        <div className='row col24-min col24-sm col24-md' style={{ width: '60.4vw', gap: '4rem', '--gutter': '0px', alignItems: "stretch", flexWrap: "wrap" }}>
            <div className='col col24-min col24-sm' style={{ '--gutter': "4rem", width: "31.2rem", gap: "2.4rem" }}>
                <div className='col' style={{ gap: "1.2rem" }}>
                    <Text className='heading-6'>Chọn sản phẩm</Text>
                    <Text className='body-3'>
                        Chọn một hoặc nhiều sản phẩm. Bạn có thể thay đổi, bổ sung khi đến giai đoạn ký hợp đồng cũng được
                    </Text>
                </div>
                <div className='col' style={{ gap: 4 }}>
                    <Text className='heading-6'>{Ultis.money(methods.watch("devices").map(e => e.Price * e["_Quantity"]).reduce((a, b) => a + b, 0))}VNĐ</Text>
                    <Text className='heading-6'>{methods.watch("devices").length}/7 sản phẩm đã chọn</Text>
                </div>
            </div>
            <div className='col' style={{ '--gutter': "4rem", flex: 1 }}>
                <div className='row' style={{ gap: 8, paddingBottom: "2.4rem", borderBottom: "var(--neutral-main-border)", overflow: "auto hidden", scrollbarWidth: "none" }}>
                    {cate.map((item) => {
                        const childrenId = (methods.watch(item.Id)?.data ?? []).map(e => e.Id)
                        const countDevice = methods.watch("devices").filter(e => childrenId.includes(e.CategoryId)).length
                        return <button key={item.Id} type='button' className={`row ${styles['category-item']} ${selectedCate === item.Id ? styles['selected'] : ""}`} onClick={() => { setSelectedCate(item.Id) }}>
                            <Text className="label-4">{item.Name}{countDevice ? ` (${countDevice})` : ""}</Text>
                        </button>
                    })}
                </div>
                {methods.watch(selectedCate)?.data?.map((item) => <CateTile key={item.Id} item={item} methods={methods} showPopupSelectProduct={showPopupSelectProduct} />)}
            </div>
        </div>
    </div>
}

const CateTile = ({ item, showPopupSelectProduct, methods }) => {
    const [onOpen, setOnOpen] = useState(true)
    const listDevices = useMemo(() => {
        return methods.getValues("devices").filter(e => e.CategoryId === item.Id)
    }, [methods.watch("devices")])

    return <div className={`col ${styles['order-detail-item']}`}>
        <div className="row" style={{ gap: "0.8rem" }}>
            <img src={ConfigApi.imgUrlId + item.Img} alt='' style={{ width: "3.2rem", height: "3.2rem", objectFit: "contain" }} />
            <div className='col' style={{ flex: 1, gap: 4 }}>
                <Text className='heading-8'>{item.Name}</Text>
                <Text className='subtitle-4'>{listDevices.length ? `${listDevices.length} sản phẩm đã chọn` : "Chọn sản phẩm"}</Text>
            </div>
            {listDevices.length ? <Button
                className='button-text-5'
                label={onOpen ? "Thu gọn" : "Chi tiết"}
                style={{ padding: "0.4rem 0.8rem", height: "fit-content" }}
                suffix={onOpen ? <Winicon src='outline/arrows/down-arrow' size={"1.1rem"} /> : <Winicon src='outline/arrows/up-arrow' size={"1.1rem"} />}
                onClick={() => { setOnOpen(!onOpen) }}
            /> :
                <Winicon src='outline/user interface/e-add' size={"1.4rem"} onClick={() => { showPopupSelectProduct(item) }} />}
        </div>
        {onOpen && listDevices.map((dev) => {
            return <div key={dev.Id} className='row' style={{ width: "100%", gap: "2.4rem" }}>
                <img src={ConfigApi.imgUrlId + dev.Img} alt='' style={{ width: "8rem", height: "8rem", objectFit: "cover" }} />
                <div className='col' style={{ flex: 1, gap: 4 }}>
                    <Text className='heading-7' style={{ width: "100%" }} maxLine={3}>{dev.Name}</Text>
                    <Text className='heading-8' style={{ color: "var(--neutral-subtitle-text-color)" }}>{Ultis.money(dev.Price)}VNĐ</Text>
                </div>
                <NumberPicker
                    value={dev["_Quantity"]}
                    min={0}
                    style={{ height: "3.2rem", width: "13.2rem" }}
                    onChange={(ev) => {
                        if (ev) methods.setValue("devices", methods.getValues("devices").map(e => e.Id === dev.Id ? { ...e, "_Quantity": ev } : e))
                        else methods.setValue("devices", methods.getValues("devices").filter(e => e.Id !== dev.Id))
                    }}
                />
            </div>
        })}
        {listDevices.length && onOpen ? <Button
            className='button-text-5'
            label={"Thêm"}
            style={{ padding: "0.4rem 0.8rem", width: "fit-content", height: "fit-content", border: "1px dashed var(--primary-main-color)", backgroundColor: "transparent", color: "var(--primary-main-color)" }}
            prefix={<Winicon src='outline/user interface/e-add' size={"1.1rem"} color='var(--primary-main-color)' />}
            onClick={() => { showPopupSelectProduct(item) }}
        /> : null}
    </div>
}

const PopupSelectProduct = forwardRef(function PopupSelectProduct({ item, onDone, selecteds = [] }, ref) {
    const filterMethods = useForm({ shouldFocusError: false })
    const colorController = new DataController("Color")
    const productController = new DataController("Product")
    const brandController = new DataController("Brands")
    const consumeController = new DataController("Consume")
    const [listBrand, setListBrand] = useState({ data: [], totalCount: undefined })
    const [listColor, setListColor] = useState({ data: [], totalCount: undefined })
    const [listConsume, setListCosume] = useState({ data: [], totalCount: undefined })
    const [products, setProducts] = useState({ data: [], totalCount: undefined })
    const [selectedItems, setSelectedItems] = useState([])
    const scrollRef = useRef()

    const getData = async (page) => {
        const res = await productController.aggregateList({ page: page ?? 1, size: 10, searchRaw: `@CategoryId:{*${item.Id}*}` })
        if (res.code === 200) setProducts({ data: page ? [...products.data, ...res.data] : res.data, totalCount: res.totalCount })
    }

    const getColors = async () => {
        const res = await colorController.getListSimple({ page: 1, size: 1000, returns: ["Id", "Name"] })
        if (res.code === 200) setListColor({ data: res.data, totalCount: res.totalCount })
    }

    const getBrands = async () => {
        const res = await brandController.getListSimple({ page: 1, size: 1000, returns: ["Id", "Name", "ParentId"] })
        if (res.code === 200) setListBrand({ data: res.data, totalCount: res.totalCount })
    }

    const getConsume = async () => {
        const res = await consumeController.getListSimple({ page: 1, size: 1000, returns: ["Id", "Name"] })
        if (res.code === 200) setListCosume({ data: res.data, totalCount: res.totalCount })
    }

    useEffect(() => {
        if (item) {
            getBrands()
            getColors()
            getConsume()
            getData()
            if (selecteds.length) setSelectedItems(selecteds)
        }
    }, [item])

    return <div className='row' style={{ width: "100vw", height: "100dvh", justifyContent: "end", alignItems: "start" }}>
        <div className='col col24-min col24-sm col20-md' style={{ "--gutter": "0px", height: "100%", width: "54%", maxWidth: 1044, backgroundColor: "var(--neutral-absolute-background-color)" }}>
            <div className='row popup-header' style={{ borderBottom: "var(--neutral-main-border)" }}>
                <Text className='heading-7' style={{ flex: 1 }}>Chọn bồn cầu</Text>
                <Winicon src='outline/user interface/e-remove' size={"1.2rem"} style={{ padding: "0.6rem", borderRadius: "50%", backgroundColor: "var(--neutral-main-background-color)" }} onClick={() => { closePopup(ref) }} />
            </div>
            <div className='row' style={{ padding: "2rem 2.4rem", borderBottom: "var(--neutral-main-border)", gap: "0.8rem" }}>
                <Select1
                    className='button-text-3'
                    style={{ width: "10.8rem", padding: "0.4rem 1.2rem" }}
                    placeholder={"Brand"}
                    options={listBrand.data.map((item) => { return { id: item.Id, name: item.Name, parentId: item.ParentId } })}
                />
                <Select1
                    className='button-text-3'
                    style={{ width: "10.8rem", padding: "0.4rem 1.2rem" }}
                    placeholder={"Color"}
                    options={listColor.data.map((item) => { return { id: item.Id, name: item.Name } })}
                />
                <div className='row' style={{ flex: 1, justifyContent: "end" }}>
                    <TextField
                        prefix={<Winicon src='outline/development/zoom' size={"1.4rem"} />}
                        className='placeholder-2'
                        style={{ width: "40%", maxWidth: "26.8rem", minWidth: "13.8rem", padding: "0.4rem 1.6rem" }}
                        placeholder='Search'
                    />
                    <button type='button' className='row' style={{ padding: "0.6rem 1.2rem", gap: "0.8rem" }}>
                        <Winicon src='outline/user interface/setup-preferences' size={"1.4rem"} />
                        <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>Filter</Text>
                    </button>
                    <div className='row divider' style={{ height: "1.2rem", margin: 0 }} />
                    <button type='button' className='row' style={{ padding: "0.6rem 1.2rem", gap: "0.8rem" }}>
                        <Winicon src='outline/user interface/enlarge' size={"1.4rem"} />
                        <Text className='button-text-3' style={{ color: "var(--neutral-text-subtitle-color)" }}>Sort</Text>
                    </button>
                </div>
            </div>
            <div className='row' style={{ flex: 1, height: "100%", width: "100%", flexWrap: "wrap", alignContent: "start", alignItems: "stretch", gap: "4.8rem 2.4rem", overflow: "hidden auto", padding: "2.4rem" }}>
                {products.data.map((item) => {
                    const isSelected = selectedItems.some(e => e.Id === item.Id)
                    return <div key={item.Id} className={`col col8 col12-min col12-sm ${styles["product-item"]} ${isSelected ? styles["selected"] : ""}`}>
                        <div onClick={() => {
                            if (isSelected) setSelectedItems(selectedItems.filter(e => e.Id !== item.Id))
                            else setSelectedItems([...selectedItems, item])
                        }}>
                            <img src={ConfigApi.imgUrlId + item.Img} alt='' />
                            <Winicon src='fill/layout/circle-check' size={"2rem"} color='var(--neutral-absolute-reverse-background-color)' />
                        </div>
                        <div className='col' style={{ gap: "1.6rem" }}>
                            <div className='col' style={{ gap: 4, flex: 1, justifyContent: "space-between" }}>
                                <NavLink to={"https://ktxgroup.com.vn/"} target='_blank'><Text className='heading-7' maxLine={2} style={{ width: "100%" }}>{item.Name}</Text></NavLink>
                                <div className='col'>
                                    <div className='row' style={{ gap: "0.8rem", borderBottom: "var(--neutral-main-border)", padding: "1.2rem 0" }}>
                                        <Winicon src='outline/development/palette' size={"1.4rem"} />
                                        <Text className='label-4' style={{ flex: 1 }}>{item.ColorId ? listColor.data.filter(e => item.ColorId.includes(e.Id)).map(e => e.Name).join(" ,") : ""}</Text>
                                    </div>
                                    <div className='row' style={{ gap: "0.8rem", borderBottom: "var(--neutral-main-border)", padding: "1.2rem 0" }}>
                                        <Winicon src='outline/shopping/label' size={"1.4rem"} />
                                        <Text className='label-4' style={{ flex: 1 }}>{item.BrandsId ? listBrand.data.filter(e => item.BrandsId.includes(e.Id)).map(e => e.Name).join(" ,") : ""}</Text>
                                    </div>
                                    <div className='row' style={{ gap: "0.8rem", borderBottom: "var(--neutral-main-border)", padding: "1.2rem 0" }}>
                                        <Winicon src='outline/education/measure-17' size={"1.4rem"} />
                                        <Text className='label-4' style={{ flex: 1 }}>{item.ConsumeId ? listConsume.data.filter(e => item.ConsumeId.includes(e.Id)).map(e => e.Name).join(" ,") : ""}</Text>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col' style={{ gap: 4 }}> */}
                            <Text className='heading-6'>{Ultis.money(item.Price)}VNĐ</Text>
                            {/* <Text className='subtitle-4'>680 reviews</Text>
                                <div className='row' style={{ gap: 4 }}>
                                    <Winicon src='fill/user interface/star' color='var(--secondary1-main-color)' size={"1.6rem"} style={{ padding: "0.2rem" }} />
                                    <Text className='label-4' style={{ flex: 1 }}>3,5</Text>
                                </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                })}
            </div>
            <div className='row popup-footer' style={{ borderTop: "var(--neutral-main-border)" }}>
                <Button
                    label="Clear"
                    style={{ width: "6rem", color: "var(--neutral-text-subtitle-color)" }}
                    onClick={() => { setSelectedItems([]) }}
                />
                <div style={{ flex: 1 }} />
                <Button
                    label="Cancel"
                    style={{ width: "7.2rem", color: "var(--neutral-text-subtitle-color)" }}
                    onClick={() => { closePopup(ref) }}
                />
                <Button
                    label={selectedItems.length ? `Selected ${selectedItems.length} items` : "Done"}
                    className={"button-primary"}
                    style={{ padding: "0 1.2rem" }}
                    onClick={() => {
                        onDone(selectedItems.map(e => {
                            const tmp = selecteds.find(el => el.Id === e.Id)
                            if (tmp) return { ...e, "_Quantity": tmp["_Quantity"] }
                            else return e
                        }))
                    }}
                />
            </div>
        </div>
    </div>
})

//#region Select Feature
const SelectFeatureStep = ({ methods }) => {

    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        <div className='col col24-min col24-sm col20-md col16-lg col8' style={{ '--gutter': '0px', gap: "4rem" }}>
            <div className='col' style={{ gap: "0.8rem" }}>
                <Text className='heading-6'>Thông tin nhà vệ sinh</Text>
                <Text className='body-3'>Giúp chúng tôi hiểu rõ hơn mong muốn của bạn</Text>
            </div>
            <div className='col' style={{ gap: "1.6rem" }}>
                <Text className='heading-7'>Loại nhà vệ sinh</Text>
                <div className='row' style={{ gap: "0.8rem", flexWrap: "wrap" }}>
                    {Array.from({ length: 4 }).map((_, i) => {
                        switch (i + 1) {
                            case ToiletType.forWorker:
                                var label = "Cho người lao động/Sản xuất"
                                break;
                            case ToiletType.forOffice:
                                label = "Văn phòng"
                                break;
                            case ToiletType.forBuilding:
                                label = "Chung cư/ Toà nhà"
                                break;
                            case ToiletType.forFamily:
                                label = "Hộ gia đình"
                                break;
                            default:
                                break;
                        }
                        return <div key={i} className='row col12 col24-min' style={{ "--gutter": "0.8rem", cursor: "pointer" }} onClick={() => { methods.setValue("Type", `${i + 1}`) }}  >
                            <RadioButtonForm
                                name='Type'
                                value={`${i + 1}`}
                                register={methods.register}
                                label={label}
                                style={{ flex: 1, gap: "1.2rem", padding: "1.6rem 2.4rem", borderRadius: "0.8rem", border: "var(--neutral-main-border)" }}
                            />
                        </div>
                    })}
                </div>
            </div>
            <div className='col' style={{ gap: "1.6rem" }}>
                <Text className='heading-7'>Địa điểm xây dựng/cải tại nhà vệ sinh</Text>
                <div className='row' style={{ gap: "0.8rem", flexWrap: "wrap" }}>
                    {Array.from({ length: 2 }).map((_, i) => {
                        switch (i + 1) {
                            case ToiletPlace.outDoor:
                                var label = "Ngoài trời"
                                break;
                            case ToiletPlace.inDoor:
                                label = "Gắn liền toà nhà"
                                break;
                            default:
                                break;
                        }
                        return <div key={i} className='row col12 col24-min' style={{ "--gutter": "0.8rem", cursor: "pointer" }} onClick={() => { methods.setValue("Place", `${i + 1}`) }}  >
                            <RadioButtonForm
                                name='Place'
                                value={`${i + 1}`}
                                register={methods.register}
                                label={label}
                                style={{ flex: 1, gap: "1.2rem", padding: "1.6rem 2.4rem", borderRadius: "0.8rem", border: "var(--neutral-main-border)" }}
                            />
                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}

//#region Input Contact
const InputContactStep = ({ methods }) => {
    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        <div className='col col24-min col24-sm col20-md col8' style={{ '--gutter': '0px', gap: "3.2rem" }}>
            <div className='col' style={{ gap: "0.8rem" }}>
                <Text className='heading-6'>Thông tin liên hệ</Text>
                <Text className='body-3'>Chúng tôi sẽ dùng thông tin này để liên lạc lại</Text>
            </div>
            <div className='col' style={{ gap: "2.4rem" }}>
                <TextFieldForm
                    name='Name'
                    label='Họ tên'
                    errors={methods.formState.errors}
                    register={methods.register}
                />
                <TextFieldForm
                    name='Mobile'
                    label='Số điện thoại'
                    errors={methods.formState.errors}
                    register={methods.register}
                />
                <div className='col' style={{ gap: "0.8rem" }}>
                    <Text className='label-3'>Địa chỉ của bạn</Text>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
}

//#region Last step
const LastStep = ({ customer, devices = [], toilet }) => {
    const { watch, setValue } = useForm({ shouldFocusError: false })
    const [status, setStatus] = useState(0)
    const optRef = useRef()
    const navigate = useNavigate()

    const _onSubmitOpt = async () => {
        const customerController = new DataController("Customer")
        const toiletController = new DataController("Toilet")
        const deviceController = new DataController("Device")
        const customerRes = await customerController.add([customer])
        if (customerRes.code !== 200) return ToastMessage.errors(customerRes.message)
        const toiletRes = await toiletController.add([toilet])
        if (toiletRes.code !== 200) return ToastMessage.errors(toiletRes.message)
        if (devices.length) await deviceController.add(devices)
        setStatus(1)
    }

    const renderByStatus = () => {
        switch (status) {
            case 1:
                return <div className='col col24-min' style={{ '--gutter': '0px', padding: "4rem 0", alignItems: "center", gap: "4rem", overflow: "visible" }}>
                    <img alt='' src="https://file-mamager.wini.vn/Upload/2024/12/register success_03ff.png" style={{ height: "40rem", maxWidth: "100%", aspectRatio: "468 / 400", objectFit: "contain", transform: "translateX(3.8rem)" }} />
                    <div className='col' style={{ gap: "0.8rem" }}>
                        <Text className='heading-5' style={{ width: "100%", textAlign: "center" }}>Thông tin đã gửi thành công</Text>
                        <Text className='body-3' style={{ width: "100%", textAlign: "center" }}>Chúng tôi sẽ liên hệ lại trong 1 ngày làm việc.</Text>
                    </div>
                    <Button
                        label='Thêm thông tin tài khoản'
                        className='button-primary button-text-1'
                        style={{ height: "4.8rem", padding: "0 3.2rem", maxWidth: "100%" }}
                        onClick={() => { navigate(-2) }}
                    />
                </div>
            default:
                return <div className='col col24-min' style={{ '--gutter': '0px', padding: "4rem 0", alignItems: "center", gap: "1.6rem", width: "42rem" }}>
                    <Winicon src='outline/technology/mobile-chat' size={"5.6rem"} />
                    <div className='col' style={{ gap: "0.8rem", alignItems: "center" }}>
                        <Text className='heading-5'>Nhập mã otp</Text>
                        <Text className='body-3'>Nhập mã otp được gửi đến số điện thoại"{customer.Mobile.substring(4)}xxxxxx"</Text>
                    </div>
                    <div ref={optRef} className='row' style={{ padding: "1.6rem 4rem", gap: "0.8rem", width: "100%", justifyContent: "center" }}
                        onMouseDown={(ev) => {
                            ev.stopPropagation()
                            ev.preventDefault()
                            const inputList = [...ev.target.closest("div").childNodes]
                            for (const [index, input] of inputList.entries()) {
                                if (!input.value.length || index === (inputList.length - 1)) {
                                    input.focus()
                                    break;
                                }
                                continue;
                            }
                        }}>
                        {Array.from({ length: 6 }).map((_, i) => <input
                            key={"opt-" + i}
                            className='body-1'
                            style={{ border: "var(--neutral-bolder-border)", flex: 1, height: "4.8rem", borderRadius: "0.4rem", textAlign: "center", minWidth: "4.8rem" }}
                            onKeyDown={(ev) => {
                                const key = ev.key.toLowerCase()
                                switch (key) {
                                    case "backspace":
                                        if (ev.target.value.length) ev.target.value = ""
                                        else if (ev.target.previousSibling?.localName === "input") ev.target.previousSibling.focus()
                                        else ev.target.blur()
                                        break;
                                    case "delete":
                                        ev.target.value = ""
                                        break;
                                    default:
                                        const numberCheck = /[0-9]/g
                                        if (numberCheck.test(key)) {
                                            if (!ev.target.value.length) ev.target.value = key
                                            if (ev.target.nextSibling?.localName === "input" && !ev.target.nextSibling.value.length) ev.target.nextSibling.focus()
                                            else ev.target.blur()
                                        } else ev.preventDefault()
                                        break;
                                }
                            }}
                            onBlur={() => {
                                setValue("opt", [...optRef.current.querySelectorAll("input")].map(v => v.value).join(""))
                            }}
                        />)}
                    </div>
                    <Button
                        label='Xác nhận'
                        className={`${watch("opt")?.length === 6 ? "button-primary" : "button-disabled"} button-text-1`}
                        style={{ width: "100%", height: "4.8rem" }}
                        onClick={_onSubmitOpt}
                    />
                </div>
        }
    }

    return <div className='row' style={{ justifyContent: "center", alignItems: "start", padding: "4rem 2.4rem", width: "100%", flex: 1, overflow: "hidden auto" }}>
        {renderByStatus()}
    </div>
}